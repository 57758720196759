import React from 'react'
import OpenModal from 'src/components/OpenModal'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import cartaoMastercardBlockJSON from '../../assets/data/cartao-mastercard-black.json'

import IcSparkle from '@interco/icons/core/action-navigation/ic_sparkle'
import IcCreditCard from '@interco/icons/core/finance/ic_credit_card'
import IcGlobe from '@interco/icons/core/home-objects/ic_globe'
import IcLuggage from '@interco/icons/core/travel-mobility/ic_luggage'
import IcPlaneDeparture from '@interco/icons/core/travel-mobility/ic_plane_departure'
import ImageWebp from 'src/components/ImageWebp'
import { Button, Section } from './style'

type CartaoMastercardBlackProps = {
  icon: string;
  description: string;
}

const CartaoMastercardBlack = ({ sendDatalayerEvent }: DataLayerProps) => {
  const icon = {
    creditCard: <IcCreditCard height={24} width={24} color='#ffffff' />,
    plane: <IcPlaneDeparture height={24} width={24} color='#ffffff' />,
    luggage: <IcLuggage height={24} width={24} color='#ffffff' />,
    globe: <IcGlobe height={24} width={24} color='#ffffff' />,
    sparkle: <IcSparkle height={24} width={24} color='#ffffff' />,
  }

  const handleIcon = (iconName: string) => {
    switch (iconName) {
      case 'creditCard':
        return icon.creditCard
      case 'plane':
        return icon.plane
      case 'luggage':
        return icon.luggage
      case 'globe':
        return icon.globe
      case 'sparkle':
        return icon.sparkle
      default:
        return icon.creditCard
    }
}

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 mb-3'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/black-dobra5/image.webp'
              arrayNumbers={[ ]}
              arrayNumbersHeight={[ ]}
              altDescription='Cartão Mastercard Black, sem anuidade e internacional'
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-28 lh-33 fs-md-28 lh-md-33 fw-700 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-white'>
              <span className='d-xl-block'>Cartão Mastercard</span> Black
            </h2>
            <p className='font-citrina fs-16 lh-19 fs-md-18 lh-md-21 fs-lh-20 lh-lg-24 fs-xl-24 lh-xl-28 fw-600 text-white'>
              É internacional, sem anuidade e com mais benefícios para você.
            </p>
            <p className='fs-14 lh-16 fw-400 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white'>
              Com o Inter Loop, você acumula 1 ponto a cada R$2,50 em compras no cartão de crédito para trocar por vantagens
              que você escolher. E o melhor: seus pontos não expiram.
            </p>
            <h3 className='fs-16 lh-19 fw-600 fs-md-20 lh-md-25 text-white'>Benefícios Mastercard</h3>
            {
              cartaoMastercardBlockJSON.map((item: CartaoMastercardBlackProps) => (
                <div className='d-flex align-items-center mb-3 mt-3' key={item.icon}>
                  {handleIcon(item.icon)}
                  <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 fw-400 text-white ml-3 mb-0'>{item.description}</p>
                </div>
              ))
            }
            <OpenModal to='modal-open-inter-black-contact-form' className='d-flex justify-content-md-center justify-content-lg-start'>
              <Button
                onClick={() => {
                sendDatalayerEvent({
                section: 'dobra_05',
                section_name: 'Cartão Mastercard Black',
                element_action: 'click button',
                element_name: 'Saber mais',
              })
                }}
              >
                Eu quero
              </Button>
            </OpenModal>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CartaoMastercardBlack
